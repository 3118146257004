  import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getRegisterTreeContent = createAsyncThunk(
  "/registerationTree/content",
  async () => {
    const response = await axios.get(
    `${process.env.REACT_APP_BASE_URLS}/trees/registeredtree`,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    );
    console.log(response, "response");
    return response.data;
  }
);

export const registerSlice = createSlice({
  name: "registerTree",
  initialState: {
    isLoading: false,
    trees: [],
  },
  reducers: {
    addNewRegisterTree: (state, action) => {
      let { newRegisterSliceObj } = action.payload;
      state.trees = [...state.trees, newRegisterSliceObj];
    },

    deleteRegisterTree: (state, action) => {
      let { index } = action.payload;
      state.trees.splice(index, 1);
    },
  },

  extraReducers: {
    [getRegisterTreeContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getRegisterTreeContent.fulfilled]: (state, action) => {
      state.trees = action.payload.data;
      state.isLoading = false;
    },
    [getRegisterTreeContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { addNewRegisterTree, deleteRegisterTree } = registerSlice.actions;

export default registerSlice.reducer;
