import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const getDonatedtreeContent = createAsyncThunk(
  "/donatedTree/content",
  async () => {
    const response = await axios.get(
    `${process.env.REACT_APP_BASE_URLS}/trees/donatedtrees`,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
    );
    console.log(response, "response");
    return response.data;
  }
);

export const donationSlice = createSlice({
  name: "donatedTree",
  initialState: {
    isLoading: false,
    trees: [],
  },
  reducers: {
    addNewDonatedtree: (state, action) => {
      let { newDontedTreeObj } = action.payload;
      state.trees = [...state.trees, newDontedTreeObj];
    },

    deleteDonatedtree: (state, action) => {
      let { index } = action.payload;
      state.trees.splice(index, 1);
    },
  },

  extraReducers: {
    [getDonatedtreeContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getDonatedtreeContent.fulfilled]: (state, action) => {
      state.trees = action.payload.data;
      state.isLoading = false;
    },
    [getDonatedtreeContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { addNewDonatedtree, deleteDonatedtree } = donationSlice.actions;

export default donationSlice.reducer;
